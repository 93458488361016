<template>
	<div>
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<b-tab
						v-for="(tabInfo, index) in tabs"
						:key="index">
						<template slot="title">
							<router-link
								:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
								:to="{ name: tabInfo.name }"
								class="list-group-item btn text-left">
								{{ translate(tabInfo.translate_key) }}
							</router-link>
						</template>
						<template
							v-if="tabIndex === index"
							class="p-0">
							<router-view />
						</template>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>
<script>

import {
	Profile,
} from '@/translations';

export default {
	name: 'CreditCard',
	messages: [Profile],
	data() {
		return {
			tabIndex: 0,
			tabs: [
				{
					name: 'AllCreditCards',
					translate_key: 'all_credit_cards',
				},
				{
					name: 'BackupCreditCards',
					translate_key: 'backup_credit_cards',
				},
			],
		};
	},
	mounted() {
		if (this.isJns()) {
			this.$router.push({ name: 'PersonalInformation' });
		}
	},
};
</script>
